import { jsPDF } from "jspdf";

const getPageQuestions = (
  texts: string[],
  maxWidth: number,
  fontSize: number
) => {
  const doc = new jsPDF();
  doc.setFontSize(fontSize);
  const heights = texts.map((text) => {
    const {h, w} = doc.getTextDimensions(text)
    return Math.ceil(w/ maxWidth) * h + 15.5
  });

  let pages: Array<Array<number>> = [];
  let page: number[] = []
  let pageTotal = 0
  heights.forEach((height, index) => {
    if (pageTotal + height > 205) {
        pages.push(page)
        pageTotal = 0
        page = [index]
    } else {
        page.push(index)
        pageTotal += height
    }

    if (index === heights.length - 1) {
        pages.push(page)
    }

  })

  return pages
};

export default getPageQuestions;
