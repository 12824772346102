import React from "react";
import ReactDOM from "react-dom/client";
import Upload from "./pages/Upload";
import "antd/dist/reset.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Preview from "./pages/Preview";
import { ConfigProvider } from "antd";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ConfigProvider theme={{
            token: {
              // Seed Token
              colorPrimary: '#8744a4',
              borderRadius: 6
            },
    }}>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems:'center',
          height:'100vh',
          paddingBottom: 48,
          paddingLeft: 24,
          paddingRight: 24,

        }}
      >
        <Routes>
          <Route path="/file-preview/:year" element={<Preview />} />
          <Route path="/" element={<Upload />} />
        </Routes>
        <small
          style={{ position: "absolute", alignSelf:'center', bottom:30, fontSize:10 }}
        >
          Copyright © {new Date().getFullYear()} Integrated Financial Group. All Rights Reserved.
        </small>
      </div>
    </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>
);
