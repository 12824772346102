import React from "react";
import { ButtonProps, Button } from "antd";
import getPageQuestions from "../../utils/getPageQuestions";
import { jsPDF } from "jspdf";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";

type Props = {
  clients: {
    name: string;
    questions: string[];
  }[];
};

const DownloadPdfButton: React.FC<Props & ButtonProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const year = location.pathname.split('/').reverse()[0];
  console.log(year)

  const handleDownload = (clientName: string, questions: string[]) => {
    const pages = getPageQuestions(questions, 100, 10);
    const doc = new jsPDF();
    pages.forEach((pageQuestions, pageIndex) => {
      doc.setDrawColor('#8744a4');
      doc.setFillColor('#8744a4');
      doc.rect(0, 5.5, 179, 16.5,'F');
      doc.setFontSize(34);
      doc.setTextColor('white')
      doc.text(`Client Worksheet (${year})`, 25,17);
      doc.addImage("../logo.png", "PNG", 183, 5, 18, 18,'','FAST');
      doc.setTextColor('black')
      let totalOffset = 25;
      if (pageIndex === 0) {
        doc.setFontSize(9);
        doc.setFont("helvetica", "italic");
        doc.text(
          "Please upload your documents and then mark the below checkbox as uploaded or not needed",
          20,
          36
        );
        doc.setFont("helvetica", "normal");
      }
      doc.setFontSize(16);
      doc.text(clientName, 20, 28, { baseline: "top" });
      doc.setFontSize(11);
      doc.setFont("helvetica", "bold");
      doc.text("Uploaded", 152, 42, { baseline: "top" });
      doc.text("Not Needed", 175, 42, { baseline: "top" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      pageQuestions.forEach((questionIndex, i) => {
        const rows =
          Math.ceil(doc.getTextDimensions(questions[questionIndex]).w / 100) -
          1;
        doc.text(questions[questionIndex], 20, 25 + i * 20 + totalOffset, {
          maxWidth: 100,
        });
        totalOffset += rows * 5;
        const fieldOffset = 27.5 + i * 20;
        //@ts-ignore
        const responseField = new doc.AcroForm.TextField();
        doc.setDrawColor("#8744a4");
        doc.rect(19.5, fieldOffset + totalOffset - 0.5, 126, 11, "S");
        responseField.Rect = [20, fieldOffset + totalOffset, 125, 10, "F"];
        responseField.multiline = true;
        doc.addField(responseField);
        //@ts-ignore
        const uploadCheckBox = new doc.AcroForm.CheckBox();
        doc.setDrawColor("#8744a4");
        doc.rect(155.5, fieldOffset + totalOffset - 0.5, 9, 9);
        uploadCheckBox.Rect = [156, fieldOffset + totalOffset, 8, 8, "F"];
        uploadCheckBox.appearanceState = "Off";
        uploadCheckBox.color = "#8744a4";
        doc.addField(uploadCheckBox);
        //@ts-ignore
        const notNeededCheckbox = new doc.AcroForm.CheckBox();
        doc.setDrawColor("#8744a4");
        doc.rect(180.5, fieldOffset + totalOffset - 0.5, 9, 9);
        notNeededCheckbox.Rect = [181, fieldOffset + totalOffset, 8, 8];
        notNeededCheckbox.appearanceState = "Off";
        notNeededCheckbox.color = "#8744a4";
        doc.addField(notNeededCheckbox);
      });
      doc.setFontSize(6);
      doc.text(`Page ${pageIndex + 1} of ${pages.length}`, 20, 292);
      doc.setFontSize(10);
      if (pages.length > pageIndex + 1) {
        doc.addPage();
      }
    });

    doc.save(
      `${clientName
        .replace(/\s/, "")
        .replace(/&/, "and")
        .replace(/\W/g, "_")
        .toLowerCase()}_${new Date().toLocaleDateString("en-us", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      })}.pdf`
    );
  };

  return (
    <div style={{ display: "flex", marginTop: 48, columnGap:12 }}>
      <Button
        type="default"
        size="small"
        style={{ flex:1, height:36 }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowLeftOutlined />
        Back
      </Button>
      <Button
        type="primary"
        size="small"
        disabled={props.clients.length === 0}
        style={{flex:1, height: 36}}
        onClick={() => {
          props.clients.forEach((client) => {
            handleDownload(client.name, client.questions);
          });
        }}
      >
        Download PDF{props.clients.length > 1 ? "s" : null}
        <DownloadOutlined />
      </Button>
    </div>
  );
};

export default DownloadPdfButton;
