import { useEffect, useState } from "react";
import readXlsxFile, { readSheetNames } from "read-excel-file";
import {
  Upload as AntdUpload,
  UploadProps,
  message,
  UploadFile,
  Button,
  Select,
  Typography,
} from "antd";
import { InboxOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Dragger } = AntdUpload;

const Upload = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState<UploadFile<any>[]>([]);
  const [data, setData] = useState<Array<Array<string>>>([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  useEffect(() => {
    if (files) {
      const sheetPromises: Promise<any>[] = [];
      const dataPromises: Promise<any>[] = [];
      files.forEach((file) => {
        dataPromises.push(
          new Promise(async (resolve, reject) => {
            const sheetNames = await readSheetNames(file.originFileObj!);
            sheetNames.forEach(async (sheet) => {
              const result = readXlsxFile(file.originFileObj!, { sheet }).then(
                (data) => data
              );
              sheetPromises.push(result);
            });

            resolve(sheetPromises);
          })
        );
      });

      Promise.all(dataPromises)
        .then((data) => {
          Promise.all(sheetPromises).then((res) => {
            setData(res);
          });
        })
        .catch((err) => {
          message.error("An error occured while processing files");
          setData([]);
        });
    }
  }, [files]);

  useEffect(() => {
    if (availableYears.length === 0) {
      let currentYear = year + 1;
      let availableYears = [];
      while (currentYear >= 2022) {
        availableYears.push(currentYear);
        currentYear--;
      }
     
      setAvailableYears(availableYears);
    }
  }, [availableYears, year]);

  const props: UploadProps = {
    name: "file",
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload: () => Promise.resolve(false),
    onChange(info) {
      setFiles(info.fileList);
    },
    style: {
      padding: 24,
      marginBottom: 24,
      display:'flex',
      justifyContent:'center'
    }
  };

  useEffect(() => {
console.log(year)
  }, [year])
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 10,
          marginBottom: 24,
        }}
      >
        <Typography.Text strong>Select Year</Typography.Text>
        <Select
          onChange={setYear}
          defaultValue={year}
          style={{ textAlign: "right" }}
        >
          {availableYears.map((year) => (
            <Select.Option value={year}>{year}</Select.Option>
          ))}
        </Select>
      </div>
      <Dragger
        {...props}
        style={{
          backgroundColor: "#f3f3f3",
          width: 500,
          minHeight: 300,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderColor: "#8744a4",
          borderWidth: 2,
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file(s) to upload</p>
      </Dragger>
      {files.length > 0 ? (
        <Button
          type="primary"
          size="small"
          style={{
            width:'100%',
            alignSelf: "center",
            paddingLeft: 24,
            paddingRight: 24,
            marginTop: 36,
            height: 36,
            fontSize:16,
            fontWeight:'500'
          }}
          onClick={() => {
            localStorage.setItem("data", JSON.stringify(data));

            navigate(`/file-preview/${year}`);
          }}
        >
          Continue <ArrowRightOutlined />
        </Button>
      ) : null}
    </div>
  );
};

export default Upload;
