import { Typography, List, Checkbox } from "antd";
import { useState } from "react";
import DownloadPdfButton from "../components/download-button";

const Preview = () => {
  const data = JSON.parse(
    localStorage.getItem("data") || JSON.stringify([[]])
  ) as Array<Array<Array<string>>>;
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>(
    data.map((d, index) => index)
  );

  if (data.length === 0) {
    return <Typography.Text type="danger">No files found</Typography.Text>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        marginTop: 24,
      }}
    >
      <Typography.Title level={3} style={{ marginBottom: 24 }}>
        Verify the files that you wish to process
      </Typography.Title>
      <List
        dataSource={data}
        bordered
        size="large"
      
        style={{
          maxHeight: "80vh",
          overflowY: "scroll",
        }}
        renderItem={(item, index) => (
          <List.Item >
            <List.Item.Meta
              title={
                <Typography.Title level={5}>{item[0][1]}</Typography.Title>
              }
              description={
                <Typography.Text type="secondary" strong>{`${
                  item.length - 3
                } questions detected`}</Typography.Text>
              }
            ></List.Item.Meta>
            <Checkbox
              defaultChecked={selectedFiles.includes(index)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedFiles([...selectedFiles, index]);
                } else {
                  setSelectedFiles(
                    [...selectedFiles].filter((s) => s !== index)
                  );
                }
              }}
            ></Checkbox>
          </List.Item>
        )}
      />
      <DownloadPdfButton
        clients={data
          .filter((d, index) => selectedFiles.includes(index))
          .map((d) => ({
            name: d[0][1],
            questions: d.filter((d, index) => index > 2).map((d) => d[1]),
          }))}
      />
    </div>
  );
};

export default Preview;
